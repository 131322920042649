// 1px=3/4 pt=0.75pt
// a4 height: 595.28pt; width: 841.89pt;

.page {
  background-color: #DFDEE3;
  // padding-bottom: 60px;
}
.wrapper {
  // box-sizing: content-box;
  // width: 841.89pt;
  width: 1122.52px;
  margin: auto;
}

.unprintWrapperMobile {
  display: flex;
  justify-content: space-between;
  background-color: #EDEDED;
  padding: 20px 40px;
  margin-bottom: 40px;
}
.unprintWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #DBDBDB;
  background-color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.btn {
  color: #3A81F6;
  margin-left: 8px;
}
.btnMobile {
  color: #3A81F6;
  margin-left: 8px;
}
.btnShare {
  color: #10B981;
  font-size: 40px;
  margin-left: 8px;
}
.shareTip {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.35);
}

.a4Divider {
  margin-bottom: 12px;
}
.a4 { 
  position: relative;
  // width: 841.89pt;
  width: 1122.52px;
  height: 793.7px;
  padding: 40px 56px 40px 70px; 
  // box-shadow: 0 0 15px 0 rgba(38,55,86,0.1);
  background-color: #fff;
}

.footer {
  position: absolute;
  bottom: 30px;
  left: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 126px);

  .footerLeft {
    font-size: 18px;
    color:rgba(0,0,0,0.5);
  }
  .footerRight {
    font-size: 18px;
    color:rgba(0,0,0,0.5)
  }
}

.page1 {
  text-align: center;
  padding: 130px 0;
  // box-sizing: content-box;

  .page1Title {
    font-size: 52px;
    font-weight: bold;
    
  }
  .page1TitleDivider {
    border-bottom: 1px dotted #333;
    margin: 40px auto;
    width: 620px;
  }
}

.page2Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  margin: 40px 0;
  border-bottom: 2px dotted #000;

  .page2HeaderLeft {
    // display: flex;
    // align-items: center;
    vertical-align: top;
  
    .page2Title {
      display: inline-block;
      width: 286px;
      line-height: 1;
      
      svg {
        height: 36px;
      }
    }
    .page2Price {
      display: inline-block;
      height: 42px;
      line-height: 1;
      margin-left: 24px;
      vertical-align: top;
      margin-top: -5px;
  
      .page2PriceYuan {
        display: inline-block;
        font-size: 42px;
        height: 42px;
        font-weight: bold;
        vertical-align: top;

      }
      .page2PriceJiao {
        display: inline-block;
        font-size: 22px;
        font-weight: bold;
        margin-top: 18px;
        vertical-align: top;
      }
    }
  }
  
  .page2HeaderRight {
    font-size: 24px;
  }
}
.page2Body {
  display: flex;

  .page2BodyTitle {
    height: 28px;
    margin-bottom: 40px;
  }

  .page2Left {
    width: 60%;
  }
  .page2Right {
    width: 30%;
    margin-left: 5%;
  }
}

.table {
  width: 100%;
  text-indent: initial;
  border-collapse: collapse;

  thead {
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
  }

  th {
    height: 50px;
    line-height: 1.1;
    font-size: 18px;
  }
  th:first-child {
    border-right: 2px solid #333;
    width: 130px;
  }
  th:last-child {
    // min-width: 90px;
    border-left: 2px solid #333;
  }

  th, td {
    text-align: center;
  }

  td {
    height: 32.5px;
    border: 1px dotted #333;
  }
  tbody tr td:first-child,
  tfoot tr td:first-child {
    border-right: 2px solid #333;
    border-left: 0;
  }
  tbody tr td:last-child,
  tfoot tr td:last-child {
    border-left: 2px solid #333;
    border-right: 0;
  }

  tbody tr:last-child td {
    border-bottom: 2px solid #333;
  }

  tfoot tr:last-child td {
    border-left: 0;
    border-right: 0;
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
    padding: 12px 24px;
  }
}

.pcTip {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.35);

  .pcTipWrapper {
    position: fixed;
    width: 80vw;
    height: 65vh;
    top: 10vh;
    left: 10vw;
    background: #fff;
    border-radius: 50px;

    .title {
      font-size: 60px;
      font-weight: bold;
      text-align: center;
      padding: 60px 0;
      border-bottom: 1px solid #999;
    }
    .body {
      padding: 80px 0 0 60px;
    }
    .li {
      display: flex;
      margin-bottom: 80px;
    }
    .liIndex {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #000;
      font-size: 60px;
      color: #fff;
      text-align: center;
      line-height: 100px;
      font-style: italic;
      font-weight: bold;
      margin-right: 50px;
    }
    .liTitle {
      font-size: 56px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .liDesc {
      font-size: 50px;
      color: rgba(0,0,0,0.75);
    }

    
  }
  .close {
    font-size: 160px;
    position: absolute;
    bottom: 10vh;
    left: 50%;
    margin-left: -80px;
    color: #fff;
  }
}